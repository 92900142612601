import React, { FC } from 'react'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import styles from './DesktopHeaderV2.module.scss'
import SearchInput from '../Search/SearchInput'
import IconActions from '../IconActions/IconActions'
import CategoriesCTAWrapper from './CategoriesCTA/CategoriesCTAWrapper'
import SALogo from '../IconActions/SALogo'
import { submitSearch } from 'components/Header/Navigation/Search/helpers'
import classNames from 'classnames'

const DesktopHeaderV2: FC<UserPreferencesProps> = ({ isAuthenticated, userPreferences }) => {
  const handleSearch = (query: string) => {
    submitSearch({
      query,
      type: 'art',
    })
  }

  return (
    <div className={styles.headerContainer} data-type='desktop-header'>
      <div className={styles.headerLogoActionsContainer}>
        <SALogo />
        <div className={styles.headerSearchActionsContainer}>
          <div className={styles.headerSearchBox}>
            {!!userPreferences?.country && <SearchInput onSearch={handleSearch} />}
          </div>
          <div
            className={classNames(styles.headerActionsBox, {
              [styles.headerActionsBoxLogged]: isAuthenticated,
            })}
          >
            <IconActions />
          </div>
        </div>
      </div>
      <CategoriesCTAWrapper />
    </div>
  )
}

export default UserPreferences(DesktopHeaderV2)
