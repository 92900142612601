type Link = {
  className?: string
  linkTitle: string
  url: string
}
type LinksMap = Record<string, Array<Link>>
export const categories = [
  {
    section: 'buyers',
    title: 'For Buyers',
  },
  {
    section: 'artists',
    title: 'For Artists',
  },
  {
    section: 'about',
    title: 'About Us',
  },
  {
    section: 'saatchi',
    title: 'Saatchi Art',
  },
  {
    section: 'top_categories',
    title: 'Top Categories',
  },
]
export const links: LinksMap = {
  buyers: [
    {
      url: '/artadvisory',
      linkTitle: 'Art Advisory Services',
    },
    {
      url: 'https://support.saatchiart.com/hc/en-us/categories/200254317-For-Buyers',
      linkTitle: 'Buyer FAQ',
    },
    {
      url: 'https://support.saatchiart.com/hc/en-us/articles/14673570463643',
      linkTitle: 'Return Policy',
    },
    {
      url: '/testimonials',
      linkTitle: 'Testimonials',
    },
    {
      url: `/prints`,
      linkTitle: 'Art Prints',
    },
    {
      url: '/loyalty',
      linkTitle: 'Curator’s Circle',
    },
    {
      url: '/catalog',
      linkTitle: 'Catalog',
    },
    {
      url: '/giftcard',
      linkTitle: 'Gift Card',
    },
    {
      url: '/commissions',
      linkTitle: 'Commissions',
    },
  ],
  artists: [
    {
      url: '/whysell',
      linkTitle: 'Why Sell',
    },
    {
      url: 'https://support.saatchiart.com/hc/en-us',
      linkTitle: 'Artist Handbook',
    },
  ],
  about: [
    {
      url: 'https://www.saatchiart.com/about-us',
      linkTitle: 'About',
    },
    {
      url: '/press',
      linkTitle: 'Press',
    },
    {
      url: 'https://www.linkedin.com/company/saatchiart/jobs/',
      linkTitle: 'Careers',
    },
    {
      url: 'https://support.saatchiart.com/hc/requests/new',
      linkTitle: 'Contact Us',
    },
    {
      url: 'https://canvas.saatchiart.com/',
      linkTitle: 'Blog',
    },
    {
      url: '/stories',
      linkTitle: 'Saatchi Art Stories',
    },
  ],
  saatchi: [
    {
      url: '/terms',
      linkTitle: 'Terms of Service',
    },
    {
      url: '/privacy',
      linkTitle: 'Privacy Notice',
    },
    {
      url: '/cookie_notice',
      linkTitle: 'Cookie Notice',
    },
    {
      url: '/copyright',
      linkTitle: 'Copyright Policy',
    },
    {
      url: 'https://signup.cj.com/member/brandedPublisherSignUp.do?air_refmerchantid=4023958',
      linkTitle: 'Affiliate Program',
    },
    {
      url: '/accessibility',
      linkTitle: 'Accessibility',
    },
    {
      url: '/giftcardterms',
      linkTitle: 'Gift Card Terms',
    },
    {
      url: '/privacy#12.2',
      linkTitle: 'California Notice of Collection',
    },
    {
      url: '#userPreferences',
      linkTitle: 'Do Not Sell or Share My Personal Information',
      className: 'ot-sdk-show-settings',
    },
  ],
  top_categories: [
    {
      url: '/paintings',
      linkTitle: 'Paintings',
    },
    {
      url: '/photography',
      linkTitle: 'Photography',
    },
    {
      url: '/sculpture',
      linkTitle: 'Sculpture',
    },
    {
      url: '/drawings',
      linkTitle: 'Drawings',
    },
    {
      url: '/collage',
      linkTitle: 'Collage',
    },
  ],
}