import React from 'react'
import styles from './SALogo.module.scss'
import SaatchiLogo from 'svg/saatchi-only-logo.svg'
import SaatchiTypography from 'svg/saatchi-typography.svg'
import SALink from 'components/SALink'

const SALogo = () => {
  return (
    <SALink
      className={styles.mobileSaLogoBox}
      alt='Saatchi Art logo'
      aria-label='Saatchi Art logo'
      data-type='logo'
      data-gtm-event='nav header'
      href={process.env.SITE_URL || '/'}
    >
      <SaatchiLogo className={styles.mobileSaLogoIcon} />
      <SaatchiTypography className={styles.mobileSaLogoTypography} />
    </SALink>
  )
}

export default SALogo
