import React, { FC } from 'react'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import User from './User/User'
import Cart from './Cart/Cart'
import styles from './IconActions.module.scss'
import SearchIcon from 'svg/search-icon.svg'
import { useHeaderV2Context } from '../HeaderV2Context'
import StudioLink from 'components/Header/Navigation/StudioLink'
import CountryFlagIcon from './CountryFlag/CountryFlagIcon'
import FavoritesIcon from './Favorites/FavoritesIcon'

const IconActions: FC<UserPreferencesProps> = ({ userPreferences }) => {
  const { setMobileHeaderConfig } = useHeaderV2Context()

  const handleSearchClick = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      mobileTopHeader: 'search',
      mobileMenuContent: 'search',
      isMobileMenuOpen: true,
    }))
  }

  // only to apply loading at the same time as the other icons
  if (!userPreferences?.country) {
    return null
  }

  return (
    <div className={styles.icons}>
      <div onClick={handleSearchClick} className={styles.searchIconContainer}>
        <SearchIcon />
      </div>
      <div className={styles.studioIconContainer}>
        <StudioLink />
      </div>
      <CountryFlagIcon />
      <FavoritesIcon />
      <User />
      <Cart />
    </div>
  )
}

export default UserPreferences(IconActions)
