import React, { useState, useEffect } from 'react'
import type { ConnectorProps } from 'connectors/UserPreferences'
import UserPreferences from 'connectors/UserPreferences'
import DisplayUnit from 'components/MeasurementUnit/DisplayUnit'
import { flagPositions } from 'styles/flags'
import NextIcon from 'svg/caret-right.svg'
import { updateMyUserPreferences } from 'apis/account/userPreferences'
import { fetchAllClientData } from 'apis/client'
import { setUserPreferencesCookie } from './apis'
import PreferencesModal from './Modals/Preferences'
import { Preferences } from './styles'

const Component = (
  props: ConnectorProps
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    availableCountries,
    localeIsReady,
    userPreferences,
    selectedCurrency,
    isAuthenticated,
    userPreferencesMeasurementUnit,
    updateUserPreferences,
    availableCurrencies,
  } = props
  const [openModal, handleModal] = useState(false)
  const { country, currency } = userPreferences
  const { currencyCode } = selectedCurrency
  const countryName = availableCountries ? availableCountries[country] : ''
  const currencyName = availableCurrencies[currency]

  // the below code is to support users who have unsupported countries/currencies already selected
  // reset the session and cookie values to US/USD
  // We will delete this code in a year
  const updateAllClientData = async () => {
    // Fetch Session and Cart data
    const { payload, success } = await fetchAllClientData()

    if (!success) {
      console.error('Problem Fetching Session')
      return
    }

    updateUserPreferences(payload)
  }

  const setDefaultCountryPreferences = async () => {
    await updateMyUserPreferences({
      country: 'US',
      currency: currencyCode,
      measurementUnit: userPreferencesMeasurementUnit,
    })
    const { payload } = await setUserPreferencesCookie({
      country: 'US',
      language: 'us',
      currency: currencyCode,
      measurementUnit: userPreferencesMeasurementUnit,
    })
    updateAllClientData(payload)
  }

  const setDefaultCurrencyPreferences = async () => {
    await updateMyUserPreferences({
      country,
      currency: 'USD',
      measurementUnit: userPreferencesMeasurementUnit,
    })
    const { payload } = await setUserPreferencesCookie({
      country,
      language: 'us',
      currency: 'USD',
      measurementUnit: userPreferencesMeasurementUnit,
    })
    updateAllClientData(payload)
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (!countryName) {
        setDefaultCountryPreferences()
      }

      if (!currencyName) {
        setDefaultCurrencyPreferences()
      }
    }
  }, [isAuthenticated])

  // Hide currency selector for specific pages
  if (!localeIsReady || !userPreferences) {
    return null
  }

  return (
    <>
      <Preferences
        type='button'
        data-section='preferences'
        onClick={() => handleModal(true)}
        id='user-preferences'
      >
        <div data-type='item'>
          <i
            data-type='flag'
            style={flagPositions[(country || '').toLowerCase()]}
            title={country}
            name={(country || '').toLowerCase()}
          />
          <p data-type='country-name'>{countryName}</p>
        </div>

        <div data-type='item'>{currencyCode}</div>

        <div data-type='item'>
          <DisplayUnit />
        </div>
        <NextIcon data-type='caret' />
      </Preferences>

      {openModal && <PreferencesModal closeModal={() => handleModal(false)} />}
    </>
  )
}

export default UserPreferences(Component)
