import React from 'react'
import SALink from 'components/SALink'
import { ConnectorProps as SessionProps } from 'containers/Session'
import SessionConnect from 'containers/Session'
import StudioIcon from 'svg/studio-icon.svg'
import styles from './StudioLink.module.scss'

const StudioLink = (props: SessionProps) => {
  const { isSessionReady, authenticated, userTypeId } = props

  // Must have session | authenticated | artist
  if (!isSessionReady || !authenticated || userTypeId !== 2) {
    return null
  }

  return (
    <SALink
      data-gtm-event='nav header'
      data-type='studio'
      href='/studio'
      title='View Studio'
      rel='nofollow'
    >
      <StudioIcon className={styles.studioIcon} data-type='studio-icon' />
    </SALink>
  )
}

export default SessionConnect(StudioLink)
