import React from 'react'
import UserPreferencesConnector from 'connectors/UserPreferences'
import { DisplayUnit } from './styles'
type Props = {
  userPreferencesMeasurementUnit: 'in' | 'cm'
}

const Component = (props: Props) => {
  const { userPreferencesMeasurementUnit: measurementUnit } = props
  return <DisplayUnit data-type='measurement-unit'>{measurementUnit}</DisplayUnit>
}

export default UserPreferencesConnector(Component)