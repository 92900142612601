import React, { useMemo } from 'react'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import MobileCategories from '../MobileCategories/MobileCategories'
import MobileCategoriesOptions from '../MobileCategories/MobileCategoriesOptions'
import useDelayUnmount from 'hooks/useDelayUnmount'
import SearchMenuContent from 'components/HeaderV2/Search/SearchMenuContent'
import AccountLinksDropDown from '../../IconActions/User/AccountLinksDropDown'
import AuthLinksDropDown from 'components/HeaderV2/IconActions/User/AuthLinksDropDown'
import SessionConnector, { ConnectorProps as SessionProps } from 'containers/Session'

const MENU_CONTENT = {
  ['null']: null,
  ['categories']: MobileCategories,
  ['subCategories']: MobileCategoriesOptions,
  ['user']: AccountLinksDropDown,
  ['auth']: AuthLinksDropDown,
  ['search']: SearchMenuContent,
}

const MenuContent = ({ isSessionReady }: SessionProps) => {
  const { mobileHeaderConfig } = useHeaderV2Context()
  // this is to delay the unmount of the component to be able to see the animation
  const shouldRenderChild = useDelayUnmount(mobileHeaderConfig.isMobileMenuOpen, 500)

  const ContentComponent = useMemo(
    () => (shouldRenderChild ? MENU_CONTENT[mobileHeaderConfig.mobileMenuContent] : null),
    [shouldRenderChild, mobileHeaderConfig.mobileMenuContent]
  )

  if (!isSessionReady) {
    return null
  }

  return shouldRenderChild && ContentComponent ? <ContentComponent /> : null
}

export default SessionConnector(MenuContent)
