import React, { FC, useState } from 'react'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import PreferencesModal from 'components/Preferences/Modals/Preferences'
import styles from './CountryFlagIcon.module.scss'
import CountryFlagSvg from 'components/CountryFlag/CountryFlagSvg'

type CountryFlagIconProps = UserPreferencesProps

const CountryFlagIcon: FC<CountryFlagIconProps> = ({ userPreferences }) => {
  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false)

  return (
    <>
      <div
        title='View Store Preferences'
        className={styles.countryFlagBox}
        onClick={() => setIsPreferencesModalOpen(true)}
        data-type='country-flag-icon'
      >
        <CountryFlagSvg country={userPreferences?.country?.toLowerCase()} />
      </div>
      {isPreferencesModalOpen && (
        <PreferencesModal closeModal={() => setIsPreferencesModalOpen(false)} />
      )}
    </>
  )
}

export default UserPreferences(CountryFlagIcon)
