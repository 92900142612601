import React from 'react'
import ArrowRightIcon from 'svg/chevron-right.svg'
import styles from './MobileCategories.module.scss'
import HR from 'components/HR'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import classNames from 'classnames'
import AccountIcon from 'svg/account-icon.svg'
import UserIcon from 'svg/user.svg'
import SessionConnector, { ConnectorProps as SessionProps } from 'containers/Session'
import SALink from 'components/SALink'
import debounceFunc from 'lib/debounce'

const MobileCategories = ({ authenticated, fullName }: SessionProps) => {
  const {
    setSelectedCategory,
    setMobileHeaderConfig,
    mobileHeaderConfig,
    navigationLinks,
  } = useHeaderV2Context()
  const debounce = debounceFunc()

  const handleClickCategory = (categoryTitle: string) => {
    debounce(() => {
      setSelectedCategory(categoryTitle)
      setMobileHeaderConfig((previousMobileHeaderConfig) => ({
        ...previousMobileHeaderConfig,
        mobileMenuContent: 'subCategories',
      }))
    }, 200)
  }

  const handleClickAccount = () => {
    debounce(() => {
      setMobileHeaderConfig((previousMobileHeaderConfig) => ({
        ...previousMobileHeaderConfig,
        mobileTopHeader: 'account',
        mobileMenuContent: authenticated ? 'user' : 'auth',
      }))
    }, 200)
  }

  return (
    <div
      className={classNames(styles.mobileCategoriesContainer, {
        [styles.mobileCategoriesContainerOpened]: mobileHeaderConfig.isMobileMenuOpen,
      })}
    >
      {navigationLinks.map(({ title, links, url }) => (
        <div className={styles.mobileCategoriesCategoryBox} key={title}>
          {links.length > 0 ? (
            <div
              className={styles.mobileCategoriesCategoryArrow}
              onClick={() => handleClickCategory(title)}
            >
              <span className='defaultTypographyH5'>{title}</span>
              <ArrowRightIcon />
            </div>
          ) : (
            <SALink className={classNames('defaultTypographyH5', 'defaultLink')} href={url}>
              {title}
            </SALink>
          )}
          <HR />
        </div>
      ))}
      <div className={styles.mobileCategoriesCategoryArrow} onClick={handleClickAccount}>
        <span className={classNames('defaultTypographyH5', styles.mobileCategoriesCategoryAccount)}>
          {authenticated ? (
            <UserIcon className={styles.mobileCategoriesCategoryAccountIcon} />
          ) : (
            <AccountIcon className={styles.mobileCategoriesCategoryAccountIcon} />
          )}
          {authenticated ? fullName : 'Log In / Register'}
        </span>
        <ArrowRightIcon />
      </div>
      <HR />
    </div>
  )
}

export default SessionConnector(MobileCategories)
