import React from 'react'
import styles from './MainTopHeader.module.scss'
import HamburgerMenuIcon from 'svg/hamburger-menu.svg'
import CloseIcon from 'svg/close.svg'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import SALogo from 'components/HeaderV2/IconActions/SALogo'
import IconActions from 'components/HeaderV2/IconActions/IconActions'

const MainTopHeader = () => {
  const { setSelectedCategory, setMobileHeaderConfig, mobileHeaderConfig } = useHeaderV2Context()

  const handleToggleHamburgerMenu = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      mobileMenuContent: 'categories',
      isMobileMenuOpen: !previousMobileHeaderConfig.isMobileMenuOpen,
    }))
    setSelectedCategory(null)
  }

  return (
    <>
      <div className={styles.topHeaderMenuIconBox}>
        <div onClick={handleToggleHamburgerMenu}>
          {mobileHeaderConfig.isMobileMenuOpen ? (
            <CloseIcon className={styles.closeHamburgerIcon} />
          ) : (
            <HamburgerMenuIcon className={styles.hamburguerIcon} />
          )}
        </div>
        <SALogo />
      </div>
      <div>
        <IconActions />
      </div>
    </>
  )
}

export default MainTopHeader
