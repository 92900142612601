import React from 'react'
type Props = {
  selected: string
  onChange: (...args: Array<any>) => any
}
const NAME = 'measurementUnit'
const DATA = {
  in: 'Inches/Pounds',
  cm: 'Centimeters/Kilograms',
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { onChange, selected } = props
  const dataArray = Object.entries(DATA) || []
  return (
    <div data-type='input'>
      <label htmlFor={NAME}>
        <span>Measurement*</span>
        <input type='text' value={DATA[selected]} disabled />
      </label>

      <select id={NAME} name={NAME} onChange={onChange} value={selected}>
        {dataArray.map((item) => {
          const [key, name] = item
          return (
            <option key={key} value={key}>
              {String(name)}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default Component