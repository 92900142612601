import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const Form = styled.form`
  position: relative;
  width: 100%;

  &[data-form='user-preferences'] {
  }

  p {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-type='title'] {
      font-size: 24px;
      line-height: 31px;
      color: #333333;
      padding: 0;
      margin: 0 auto 15px;
    }
  }

  fieldset {
    display: flex;
    flex-direction: column;
    border: none;
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }

  [data-type='input'] {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 5px 15px;
    border: 1px solid #3333;

    label {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 16px;
      color: #666666;
    }

    input {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: #333333;
      background: transparent;
      border: transparent;
      padding: 0;
      &[disabled] {
        color: #333333;
        -webkit-text-fill-color: #333333;
        opacity: 1;
      }
    }

    select {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      min-height: 45px;
      cursor: pointer;
    }
    + [data-type='input'] {
      margin-top: 15px;
    }
  }

  input {
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    margin: 20px 0 5px;
    padding: 0 20px;
    border: none;
    outline: none;
    background-color: #dddddd;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

    &:focus {
      outline: 1px solid #333333;
    }

    &[valid],
    &:not([disabled]) {
      cursor: pointer;
      background-color: #333333;
    }
  }

  [data-type='error'] {
    width: 100%;
    padding: 10px;
    color: #cc3333;
  }

  [data-type='loading'] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
`