import styled from 'styled-components'
export const Flag = styled.span``
export default Flag // Flag sprite:
// https://d3t95n9c6zzriw.cloudfront.net/common/sprite-flag-3.png
// The first position in the sprite is left blank in case we do not have the country listed { background-position: 0 0; }

export const flagPositions = {
  an: {
    backgroundPosition: '0 -47px',
  },
  ar: {
    backgroundPosition: '0 -94px',
  },
  at: {
    backgroundPosition: '0 -141px',
  },
  au: {
    backgroundPosition: '0 -188px',
  },
  ba: {
    backgroundPosition: '0 -235px',
  },
  be: {
    backgroundPosition: '0 -282px',
  },
  bg: {
    backgroundPosition: '0 -329px',
  },
  bh: {
    backgroundPosition: '0 -376px',
  },
  bm: {
    backgroundPosition: '0 -423px',
  },
  br: {
    backgroundPosition: '0 -470px',
  },
  ca: {
    backgroundPosition: '0 -516px',
  },
  ch: {
    backgroundPosition: '0 -564px',
  },
  cn: {
    backgroundPosition: '0 -611px',
  },
  co: {
    backgroundPosition: '0 -658px',
  },
  cz: {
    backgroundPosition: '0 -705px',
  },
  de: {
    backgroundPosition: '0 -752px',
  },
  dk: {
    backgroundPosition: '0 -799px',
  },
  es: {
    backgroundPosition: '0 -846px',
  },
  fi: {
    backgroundPosition: '0 -893px',
  },
  fr: {
    backgroundPosition: '0 -940px',
  },
  ae: {
    backgroundPosition: '0 -987px',
  },
  gb: {
    backgroundPosition: '-58px 0',
  },
  ge: {
    backgroundPosition: '-58px -47px',
  },
  gr: {
    backgroundPosition: '-58px -94px',
  },
  hk: {
    backgroundPosition: '-58px -141px',
  },
  hu: {
    backgroundPosition: '-58px -188px',
  },
  id: {
    backgroundPosition: '-58px -235px',
  },
  ie: {
    backgroundPosition: '-58px -282px',
  },
  il: {
    backgroundPosition: '-58px -329px',
  },
  in: {
    backgroundPosition: '-58px -376px',
  },
  it: {
    backgroundPosition: '-58px -423px',
  },
  jp: {
    backgroundPosition: '-58px -470px',
  },
  kr: {
    backgroundPosition: '-58px -516px',
  },
  kw: {
    backgroundPosition: '-58px -564px',
  },
  ky: {
    backgroundPosition: '-58px -611px',
  },
  lb: {
    backgroundPosition: '-58px -658px',
  },
  lt: {
    backgroundPosition: '-58px -705px',
  },
  lu: {
    backgroundPosition: '-58px -752px',
  },
  lv: {
    backgroundPosition: '-58px -799px',
  },
  mc: {
    backgroundPosition: '-58px -846px',
  },
  me: {
    backgroundPosition: '-58px -893px',
  },
  mt: {
    backgroundPosition: '-58px -940px',
  },
  bo: {
    backgroundPosition: '-58px -987px',
  },
  mx: {
    backgroundPosition: '-116px 0',
  },
  my: {
    backgroundPosition: '-116px -47px',
  },
  nl: {
    backgroundPosition: '-116px -94px',
  },
  no: {
    backgroundPosition: '-116px -141px',
  },
  nz: {
    backgroundPosition: '-116px -188px',
  },
  pe: {
    backgroundPosition: '-116px -235px',
  },
  ph: {
    backgroundPosition: '-116px -282px',
  },
  pl: {
    backgroundPosition: '-116px -329px',
  },
  pr: {
    backgroundPosition: '-116px -376px',
  },
  pt: {
    backgroundPosition: '-116px -423px',
  },
  qa: {
    backgroundPosition: '-116px -470px',
  },
  ro: {
    backgroundPosition: '-116px -516px',
  },
  ru: {
    backgroundPosition: '-116px -564px',
  },
  sa: {
    backgroundPosition: '-116px -611px',
  },
  se: {
    backgroundPosition: '-116px -658px',
  },
  sg: {
    backgroundPosition: '-116px -705px',
  },
  si: {
    backgroundPosition: '-116px -752px',
  },
  sk: {
    backgroundPosition: '-116px -799px',
  },
  th: {
    backgroundPosition: '-116px -846px',
  },
  tr: {
    backgroundPosition: '-116px -893px',
  },
  tw: {
    backgroundPosition: '-116px -940px',
  },
  bn: {
    backgroundPosition: '-116px -987px',
  },
  ua: {
    backgroundPosition: '-174px 0',
  },
  us: {
    backgroundPosition: '-174px -47px',
  },
  za: {
    backgroundPosition: '-174px -94px',
  },
  // missing flags
  cl: {
    backgroundPosition: '-174px -141px',
  },
  // chile,
  hr: {
    backgroundPosition: '-174px -188px',
  },
  // croatia,
  rs: {
    backgroundPosition: '-174px -235px',
  },
  // serbia,
  cr: {
    backgroundPosition: '-174px -282px',
  },
  // costa rica,
  ad: {
    backgroundPosition: '-174px -329px',
  },
  af: {
    backgroundPosition: '-174px -376px',
  },
  ag: {
    backgroundPosition: '-174px -423px',
  },
  ai: {
    backgroundPosition: '-174px -470px',
  },
  al: {
    backgroundPosition: '-174px -516px',
  },
  am: {
    backgroundPosition: '-174px -564px',
  },
  ao: {
    backgroundPosition: '-174px -611px',
  },
  as: {
    backgroundPosition: '-174px -658px',
  },
  aw: {
    backgroundPosition: '-174px -705px',
  },
  az: {
    backgroundPosition: '-174px -752px',
  },
  bb: {
    backgroundPosition: '-174px -799px',
  },
  bd: {
    backgroundPosition: '-174px -846px',
  },
  bf: {
    backgroundPosition: '-174px -893px',
  },
  bi: {
    backgroundPosition: '-174px -940px',
  },
  bj: {
    backgroundPosition: '-174px -987px',
  },
  bs: {
    backgroundPosition: '-225px 0',
  },
  bt: {
    backgroundPosition: '-225px -47px',
  },
  bw: {
    backgroundPosition: '-225px -94px',
  },
  by: {
    backgroundPosition: '-225px -141px',
  },
  bz: {
    backgroundPosition: '-225px -188px',
  },
  cd: {
    backgroundPosition: '-225px -235px',
  },
  cf: {
    backgroundPosition: '-225px -282px',
  },
  cg: {
    backgroundPosition: '-225px -329px',
  },
  ci: {
    backgroundPosition: '-225px -376px',
  },
  ck: {
    backgroundPosition: '-225px -423px',
  },
  cm: {
    backgroundPosition: '-225px -470px',
  },
  cu: {
    backgroundPosition: '-225px -516px',
  },
  cv: {
    backgroundPosition: '-225px -564px',
  },
  cw: {
    backgroundPosition: '-225px -611px',
  },
  cy: {
    backgroundPosition: '-225px -658px',
  },
  dj: {
    backgroundPosition: '-225px -705px',
  },
  dm: {
    backgroundPosition: '-225px -752px',
  },
  do: {
    backgroundPosition: '-225px -799px',
  },
  dz: {
    backgroundPosition: '-225px -846px',
  },
  ec: {
    backgroundPosition: '-225px -893px',
  },
  ee: {
    backgroundPosition: '-225px -940px',
  },
  eg: {
    backgroundPosition: '-225px -987px',
  },
  er: {
    backgroundPosition: '-275px 0',
  },
  et: {
    backgroundPosition: '-275px -47px',
  },
  fj: {
    backgroundPosition: '-275px -94px',
  },
  fk: {
    backgroundPosition: '-275px -141px',
  },
  fm: {
    backgroundPosition: '-275px -188px',
  },
  ga: {
    backgroundPosition: '-275px -235px',
  },
  gd: {
    backgroundPosition: '-275px -282px',
  },
  gf: {
    backgroundPosition: '-275px -329px',
  },
  gh: {
    backgroundPosition: '-275px -376px',
  },
  gi: {
    backgroundPosition: '-275px -423px',
  },
  gl: {
    backgroundPosition: '-275px -470px',
  },
  gm: {
    backgroundPosition: '-275px -516px',
  },
  gn: {
    backgroundPosition: '-275px -564px',
  },
  gp: {
    backgroundPosition: '-275px -611px',
  },
  gq: {
    backgroundPosition: '-275px -658px',
  },
  gt: {
    backgroundPosition: '-275px -705px',
  },
  gu: {
    backgroundPosition: '-275px -752px',
  },
  gw: {
    backgroundPosition: '-275px -799px',
  },
  gy: {
    backgroundPosition: '-275px -846px',
  },
  hn: {
    backgroundPosition: '-275px -893px',
  },
  ht: {
    backgroundPosition: '-275px -940px',
  },
  iq: {
    backgroundPosition: '-275px -987px',
  },
  ir: {
    backgroundPosition: '-325px 0',
  },
  is: {
    backgroundPosition: '-325px -47px',
  },
  jm: {
    backgroundPosition: '-325px -94px',
  },
  jo: {
    backgroundPosition: '-325px -141px',
  },
  ke: {
    backgroundPosition: '-325px -188px',
  },
  kg: {
    backgroundPosition: '-325px -235px',
  },
  kh: {
    backgroundPosition: '-325px -282px',
  },
  km: {
    backgroundPosition: '-325px -329px',
  },
  kn: {
    backgroundPosition: '-325px -376px',
  },
  kp: {
    backgroundPosition: '-325px -423px',
  },
  kz: {
    backgroundPosition: '-325px -470px',
  },
  la: {
    backgroundPosition: '-325px -516px',
  },
  lc: {
    backgroundPosition: '-325px -564px',
  },
  li: {
    backgroundPosition: '-325px -611px',
  },
  lk: {
    backgroundPosition: '-325px -658px',
  },
  lr: {
    backgroundPosition: '-325px -705px',
  },
  ls: {
    backgroundPosition: '-325px -752px',
  },
  ly: {
    backgroundPosition: '-325px -799px',
  },
  ma: {
    backgroundPosition: '-325px -846px',
  },
  md: {
    backgroundPosition: '-325px -893px',
  },
  mf: {
    backgroundPosition: '-325px -940px',
  },
  mg: {
    backgroundPosition: '-325px -987px',
  },
  mh: {
    backgroundPosition: '-375px 0',
  },
  mk: {
    backgroundPosition: '-375px -47px',
  },
  ml: {
    backgroundPosition: '-375px -94px',
  },
  mm: {
    backgroundPosition: '-375px -141px',
  },
  mn: {
    backgroundPosition: '-375px -188px',
  },
  mo: {
    backgroundPosition: '-375px -235px',
  },
  mp: {
    backgroundPosition: '-375px -282px',
  },
  mq: {
    backgroundPosition: '-375px -329px',
  },
  mr: {
    backgroundPosition: '-375px -376px',
  },
  ms: {
    backgroundPosition: '-375px -423px',
  },
  mu: {
    backgroundPosition: '-375px -470px',
  },
  mv: {
    backgroundPosition: '-375px -516px',
  },
  mw: {
    backgroundPosition: '-375px -564px',
  },
  mz: {
    backgroundPosition: '-375px -611px',
  },
  na: {
    backgroundPosition: '-375px -658px',
  },
  ne: {
    backgroundPosition: '-375px -705px',
  },
  ng: {
    backgroundPosition: '-375px -752px',
  },
  ni: {
    backgroundPosition: '-375px -799px',
  },
  np: {
    backgroundPosition: '-375px -846px',
  },
  om: {
    backgroundPosition: '-375px -893px',
  },
  pa: {
    backgroundPosition: '-375px -940px',
  },
  pf: {
    backgroundPosition: '-375px -987px',
  },
  pg: {
    backgroundPosition: '-425px 0',
  },
  pk: {
    backgroundPosition: '-425px -47px',
  },
  pn: {
    backgroundPosition: '-425px -94px',
  },
  pw: {
    backgroundPosition: '-425px -141px',
  },
  py: {
    backgroundPosition: '-425px -188px',
  },
  rw: {
    backgroundPosition: '-425px -235px',
  },
  sb: {
    backgroundPosition: '-425px -282px',
  },
  sc: {
    backgroundPosition: '-425px -329px',
  },
  sl: {
    backgroundPosition: '-425px -376px',
  },
  sm: {
    backgroundPosition: '-425px -423px',
  },
  sn: {
    backgroundPosition: '-425px -470px',
  },
  so: {
    backgroundPosition: '-425px -516px',
  },
  sr: {
    backgroundPosition: '-425px -564px',
  },
  ss: {
    backgroundPosition: '-425px -611px',
  },
  st: {
    backgroundPosition: '-425px -658px',
  },
  sv: {
    backgroundPosition: '-425px -705px',
  },
  sx: {
    backgroundPosition: '-425px -752px',
  },
  sy: {
    backgroundPosition: '-425px -799px',
  },
  sz: {
    backgroundPosition: '-425px -846px',
  },
  tc: {
    backgroundPosition: '-425px -893px',
  },
  td: {
    backgroundPosition: '-425px -940px',
  },
  tg: {
    backgroundPosition: '-425px -987px',
  },
  tj: {
    backgroundPosition: '-475px 0',
  },
  tl: {
    backgroundPosition: '-475px -47px',
  },
  tm: {
    backgroundPosition: '-475px -94px',
  },
  tn: {
    backgroundPosition: '-475px -141px',
  },
  to: {
    backgroundPosition: '-475px -188px',
  },
  tt: {
    backgroundPosition: '-475px -235px',
  },
  tv: {
    backgroundPosition: '-475px -282px',
  },
  tz: {
    backgroundPosition: '-475px -329px',
  },
  ug: {
    backgroundPosition: '-475px -376px',
  },
  um: {
    backgroundPosition: '-475px -423px',
  },
  uy: {
    backgroundPosition: '-475px -470px',
  },
  uz: {
    backgroundPosition: '-475px -516px',
  },
  va: {
    backgroundPosition: '-475px -564px',
  },
  vc: {
    backgroundPosition: '-475px -611px',
  },
  vg: {
    backgroundPosition: '-475px -658px',
  },
  vi: {
    backgroundPosition: '-475px -705px',
  },
  vn: {
    backgroundPosition: '-475px -752px',
  },
  vu: {
    backgroundPosition: '-475px -799px',
  },
  vz: {
    backgroundPosition: '-475px -846px',
  },
  ws: {
    backgroundPosition: '-475px -893px',
  },
  ye: {
    backgroundPosition: '-475px -940px',
  },
  zm: {
    backgroundPosition: '-475px -987px',
  },
  zw: {
    backgroundPosition: '-525px 0',
  },
  sd: {
    backgroundPosition: '-525px -47px',
  },

  /* if no country code, then hide the flag icon */
  '': {
    display: 'none !important',
  },
}