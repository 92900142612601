import React from 'react'
import {
  VisaIcon,
  MasterIcon,
  DiscoverIcon,
  AmericanExpressIcon,
  BlueCardIcon,
  PayPalIcon,
  SEPAIcon,
  AffirmIcon,
} from './styles'

const PaymentOptions = (): React.ReactElement<React.ComponentProps<any>, any> => (
  <div data-type='payment-icons'>
    <VisaIcon />
    <MasterIcon />
    <DiscoverIcon />
    <AmericanExpressIcon />
    <BlueCardIcon />
    <PayPalIcon />
    <SEPAIcon />
    <AffirmIcon />
  </div>
)

export default PaymentOptions