import React from 'react'
import styles from './CountryFlag.module.scss'
import { FLAGS_MAPPING } from './FlagsSvg'

type CountryFlagSvgProps = {
  country: string
}

const CountryFlagSvg = (props: CountryFlagSvgProps) => {
  const { country } = props
  const COUNTRY_NAME = `${(country || '').toLowerCase()}Flag`
  const FlagComponent = FLAGS_MAPPING[COUNTRY_NAME] || (() => null)

  return (
    <div className={styles.flagSvg}>
      <FlagComponent />
    </div>
  )
}

export default CountryFlagSvg
