import React from 'react'
import Caret from 'svg/caret-right.svg'
import SALink from 'components/SALink'
type Props = {
  links: Array<Record<string, any>>
  title: string
}

const ToggleLinks = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { links, title } = props
  const cleanTitle = (title || '').toLowerCase()
  const toggleID = `toggle-${cleanTitle.replace(/\s/gi, '-')}`
  return (
    <nav aria-label={title}>
      <input id={toggleID} type='checkbox' />
      <label htmlFor={toggleID} title={cleanTitle}>
        {title}
        <Caret />
      </label>
      <ul>
        {links.map(({ url, linkTitle, className }) => {
          const matchesTitle = linkTitle === 'Privacy Notice' || linkTitle === 'Cookie Notice'
          const target = matchesTitle ? '_blank' : ''
          const rel = matchesTitle ? 'noopener noreferrer' : ''
          return (
            <li key={url}>
              <SALink
                data-gtm-event='nav footer'
                href={url}
                className={className}
                target={target}
                rel={rel}
              >
                {linkTitle}
              </SALink>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default ToggleLinks