/* This is used by NAVIGATION */
import React from 'react'
import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'
import type { ConnectorProps as AuthenticationProps } from 'connectors/Authentication'
import AuthenticationConnect from 'connectors/Authentication'

const Modal = (props: Record<string, any>): React.ReactElement<React.ComponentProps<any>, any> => {
  const { type } = props

  if (type === 'register') {
    const AuthenticationModal = dynamic(
      import(
        /* webpackChunkName: "authentication" */
        './Register'
      ),
      noSSR
    )
    return <AuthenticationModal {...props} />
  }

  if (type === 'password') {
    const AuthenticationModal = dynamic(
      import(
        /* webpackChunkName: "authentication" */
        './ResetPassword'
      ),
      noSSR
    )
    return <AuthenticationModal {...props} />
  }

  if (type === 'prompt') {
    const AuthenticationModal = dynamic(
      import(
        /* webpackChunkName: "authentication" */
        './PromptLogin'
      ),
      noSSR
    )
    return <AuthenticationModal {...props} />
  }

  const AuthenticationModal = dynamic(
    import(
      /* webpackChunkName: "authentication" */
      './Login'
    ),
    noSSR
  )
  return <AuthenticationModal {...props} />
}

type Props = {
  reloadPage: boolean
} & AuthenticationProps

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { authenticationModal, reloadPage, showModal, updateSession } = props

  if (!authenticationModal) {
    return null
  }

  return (
    <Modal
      type={authenticationModal}
      closeModal={() => showModal('')}
      loginModal={() => showModal('login')}
      registerModal={() => showModal('register')}
      resetPasswordModal={() => showModal('password')}
      updateSession={(data) => {
        updateSession(data)

        if (reloadPage) {
          // On Login - Reload the page
          window.location.reload()
        }
      }}
    />
  )
}

export default AuthenticationConnect(Component)