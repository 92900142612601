import styled from 'styled-components'
export const Preferences = styled.div`
  position: relative;
  width: auto;
  cursor: pointer;
  min-width: 300px;
  background: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  -webkit-appearance: none;

  i[data-type='flag'] {
    transform: scale(0.7);
    background: url('https://d3t95n9c6zzriw.cloudfront.net/common/sprite-flag-3.png') no-repeat top
      left;
    background-size: 558px 1009px;
    cursor: default;
    display: block;
    float: left;
    margin: 0;
    width: 35px;
    height: 23px;
  }

  i,
  p,
  span {
    display: block;
    margin: 0;
    padding: 0;
  }

  [data-type='country-name'] {
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  [data-type='caret'] {
    width: 10px;
    height: 10px;
    margin-left: 10px;
  }

  [data-type='item'] {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin: 0;
    + [data-type='item'] {
      border-left: 1px solid #cccccc;
    }
  }

  [data-type='measurement-unit'] {
    text-transform: capitalize;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 10px 20px;
    border-radius: 90px;
    background-color: #f3f3f3;
    text-shadow: -1px -1px 1px #fff;
    justify-content: center;
    color: #666666;
    margin-bottom: 50px;

    [data-type='country-name'] {
      max-width: 150px;
    }
  }

  @media (max-width: 450px) {
    [data-type='country-name'] {
      display: none;
    }
  }
`