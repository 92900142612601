import React from 'react'
type Props = {
  data: Record<string, any>
  selected: string
  onChange: (...args: Array<any>) => any
}
const NAME = 'country'

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { data, onChange, selected } = props
  const dataArray = Object.entries(data) || []
  return (
    <div data-type='input'>
      <label htmlFor={NAME}>
        <span>Country*</span>
        <input type='text' value={data[selected]} disabled />
      </label>

      <select name={NAME} id={NAME} value={selected} onChange={onChange}>
        {dataArray.map((item) => {
          const [key, name] = item
          return (
            <option key={key} value={key}>
              {String(name)}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default Component