import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import { CloseIcon } from '../icons/modal'
export const Background = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.85);
  z-index: 1000;
`
export const Modal = styled.div`
  z-index: 1001;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  min-width: 350px;
  flex-direction: column;
  justify-content: space-between;
  &[data-type='fullscreen'] {
    width: 100vw;
    height: 100vh;
    background: ${(props) => props.theme.colors.white};
  }
`
export const CreateCollectionModal = styled(Modal)`
  width: 50%;
  [data-type='create-collection-content-container'] {
    background: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 64px 172px 142px 99px;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 32px;
  height: 51px;
  ${(props) =>
    props.gray &&
    `
      background: ${props.theme.colors.backgroundGray};
    `}
  ${(props) =>
    props.border &&
    `
      border-bottom: 1px solid ${props.theme.colors.backgroundGray};
    `}

  &[data-style='close'] {
    justify-content: flex-end;
  }
`
export const Title = styled.p`
  font-size: ${(props) => props.theme.fontSizes[4]}px;
  padding: 0;
  margin: 0;
`
export const Close = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  path,
  &:hover path {
    stroke: #000000;
    stroke-width: 1px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    path {
      fill: transparent;
    }
  }
`
export const Content = styled.div`
  background: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  overflow: auto;
  [data-type='modal'] & {
    max-height: calc(100vh - 100px);
  }
  [data-type='fullscreen'] & {
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
  }
  img[data-type='artwork'] {
    width: auto;
    height: 150px;
  }
  [data-style='modal-actions'] {
    justify-content: flex-end;
    button {
      + button {
        margin-left: 10px;
      }
    }
    [data-style='light'] {
      height: 50px;
    }
  }
`
export const FullScreenImage = styled.img`
  width: 100%;
  height: 100%;
`
export const Blocker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${(props) => props.grabImage && `display: none;`}
`
// Profile Select Artworks Modal
export const SelectArtworksImg = styled.img`
  margin: ${(props) => props.theme.space[3]}px;
  cursor: pointer;
`
