import React from 'react'
import styles from './CategoriesCTA.module.scss'
import { useHeaderV2Context } from '../../HeaderV2Context'
import debounceFunc from 'lib/debounce'
import classNames from 'classnames'
import SALink from 'components/SALink'

const CategoriesCTA = () => {
  const { setSelectedCategory, navigationLinks } = useHeaderV2Context()
  const debounce = debounceFunc()

  return (
    <ul className={styles.categoriesCtaContainer}>
      {navigationLinks.map(({ title, url }, index) => (
        <li
          className={classNames('defaultTypographyH5', styles.categoriesCtaText, {
            [styles.categoriesCtaTextUniqueLink]: title === 'Gifts',
          })}
          key={`${title}${index}`}
          onMouseOver={() =>
            debounce(() => {
              setSelectedCategory(title)
            }, 200)
          }
        >
          <SALink className={classNames('defaultLink')} href={url}>
            {title}
          </SALink>
        </li>
      ))}
    </ul>
  )
}

export default CategoriesCTA
