import React from 'react'
import { Background, Content, Modal, Header } from 'styles/modals'
import Portal from 'components/Modal/v2/Portal'
import Close from 'svg/close-x.svg'
import { PrefernecesModal } from './styles'
const defaultStyles = {
  position: 'fixed',
  top: '0',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  willChange: 'transform, opacity',
  zIndex: '1000',
  overflow: 'auto',
}
type Props = {
  children: React.ReactElement<React.ComponentProps<any>, any>
  closeModal: (...args: Array<any>) => any
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { children, closeModal } = props
  return (
    <Portal closeModal={closeModal}>
      <PrefernecesModal style={defaultStyles}>
        <Background onClick={closeModal} />

        <Modal data-type='modal'>
          <aside role='dialog' aria-modal='true' tabIndex='-1'>
            <Header data-type='header'>
              <Close data-action='close' onClick={closeModal} />
            </Header>

            <Content data-type='content'>{children}</Content>
          </aside>
        </Modal>
      </PrefernecesModal>
    </Portal>
  )
}

export default Component