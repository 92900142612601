import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const PrefernecesModal = styled.div`
  [data-type='modal'] {
    width: 450px;
    @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
      /* Full Screen */
      width: 100vh;
      height: 100vh;
      [role='dialog'] {
        overflow-y: auto;
        padding-bottom: 100px;
      }
      [data-type='content'] {
        overflow: visible;
        max-height: initial;
        justify-content: flex-start;
      }
    }
  }

  [data-type='header'] {
    align-items: flex-start;
    justify-content: flex-end;
    padding: 20px;
    p {
      margin-top: 10px;
    }
    [data-action='close'] {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      padding: 10px;
    }
  }

  [data-type='content'] {
    align-items: center;
    padding: 0 20px 30px;
    overflow: hidden;
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      padding: 0 20px 10px;
      [data-action='facebook'] {
        margin-top: 10px;
      }
      [data-type='input'] {
        margin: 5px 0 10px;
        &[data-error] {
          margin: 5px 0 20px;
        }
      }
      p {
        margin: 3px 0;
      }
    }
  }

  [data-type='buttons'] {
    display: flex;
    justify-content: space-between;
    button + button {
      margin-left: 30px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 50px;
    margin: 0;
    padding: 20px;
    border: none;
    outline: none;
    color: #ffffff;
    font-weight: bold;
    background-color: #333333;
    cursor: pointer;

    &[data-action='cancel'] {
      background-color: #ffffff;
      border: 1px solid #333333;
      color: #333333;
    }
  }
`