import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import Close from 'svg/close-x.svg'
const svgFix = `
  svg:not(:root) {
    overflow: visible;
  }
`
export const CloseIcon = styled(Close)`
  transition: 0.2s ease-in-out transform;
  width: 30px;
  height: 30px;
  path {
    stroke: ${(props) => props.theme.colors.default};
    stroke-width: 1px;
  }
  &:hover path {
    stroke: ${(props) => props.theme.colors.black};
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    path {
      fill: ${(props) => props.theme.colors.default};
    }
  }
  ${svgFix}
`