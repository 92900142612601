import React from 'react'
import {
  FacebookIcon,
  InstagramIcon,
  PinterestPIcon,
  TikTokIcon,
  TwitterIcon,
  YoutubeIcon,
} from './styles'

const SocialIcons = (): React.ReactElement<React.ComponentProps<any>, any> => (
  <>
    <div data-section='icon-links'>
      <a
        aria-label='Saatchi Art - Facebook'
        data-gtm-event='nav footer'
        data-type='social-icon'
        fb='true'
        href='http://www.facebook.com/saatchionline'
        rel='noopener noreferrer'
        target='_blank'
      >
        <FacebookIcon />
      </a>

      <a
        aria-label='Saatchi Art - Twitter'
        data-gtm-event='nav footer'
        data-type='social-icon'
        href='http://www.twitter.com/saatchiart'
        rel='noopener noreferrer'
        target='_blank'
        twitter='true'
      >
        <TwitterIcon />
      </a>

      <a
        aria-label='Saatchi Art - Instagram'
        data-gtm-event='nav footer'
        data-type='social-icon'
        href='http://instagram.com/saatchiart'
        instagram='true'
        rel='noopener noreferrer'
        target='_blank'
      >
        <InstagramIcon />
      </a>

      <a
        aria-label='Saatchi Art - Pinterest'
        data-gtm-event='nav footer'
        data-type='social-icon'
        href='http://pinterest.com/saatchiart'
        pinterest='true'
        rel='noopener noreferrer'
        target='_blank'
      >
        <PinterestPIcon />
      </a>

      <a
        aria-label='Saatchi Art - Youtube'
        data-gtm-event='nav footer'
        data-type='social-icon'
        href='https://www.youtube.com/channel/UCqfUz6UyK4kKVsnTyPoWZJw'
        rel='noopener noreferrer'
        target='_blank'
        youtube='true'
      >
        <YoutubeIcon />
      </a>

      <a
        aria-label='Saatchi Art - TikTok'
        data-gtm-event='nav footer'
        data-type='social-icon'
        href='https://www.tiktok.com/@saatchi_art'
        rel='noopener noreferrer'
        target='_blank'
        youtube='true'
      >
        <TikTokIcon />
      </a>

      <a
        aria-label='App Store'
        data-gtm-event='nav footer'
        data-type='app-link'
        href='http://m.onelink.me/a15f3286'
        rel='noopener noreferrer'
        target='_blank'
      >
        <img
          className='lazyload'
          data-src='//d3t95n9c6zzriw.cloudfront.net/footer/Download_App.svg'
          alt='Download on the App Store'
          width='75'
          height='25'
        />
      </a>
    </div>
  </>
)

export default SocialIcons