import React, { FC } from 'react'
import HeaderConnector, { ConnectorProps as HeaderProps } from 'connectors/navigation/Header'
import Favorites from 'svg/heart-icon.svg'
import SALink from 'components/SALink'
import styles from './FavoritesIcon.module.scss'

const FavoritesIcon: FC<HeaderProps> = ({ isAuthenticated, userId }) => {
  if (!isAuthenticated) {
    return null
  }

  return (
    <SALink
      aria-label='View your favorites'
      data-type='favorites-icon'
      data-gtm-event='nav header'
      href={`/account/favorite/${userId}`}
      rel='nofollow'
      title='View Favorites'
      className={styles.favoritesLink}
    >
      <Favorites />
    </SALink>
  )
}

export default HeaderConnector(FavoritesIcon)
