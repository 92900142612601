import React from 'react'
import SALink from 'components/SALink'
import SessionConnector, { ConnectorProps as SessionProps } from 'containers/Session'
import { getLogout } from 'components/Authentication/apis'
import StudioIcon from 'svg/studio.svg'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import styles from './Account.module.scss'
import classNames from 'classnames'
type Props = SessionProps & {
  dropdownIsOpen: boolean
  dropdownIsHovered: boolean
}
export const ARTIST_TYPE = 2

const ArtistLinks = ({ profileUrl, userId }) => {
  return (
    <>
      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href='/studio'
        rel='nofollow'
      >
        Studio
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href={profileUrl}
        rel='nofollow'
      >
        View Artist Profile
      </SALink>

      <span className={styles.accountDropDownContentLine} />

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href={`/u/${userId}`}
        rel='nofollow'
      >
        View Profile
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href={`/account/favorite/${userId}`}
        rel='nofollow'
      >
        Favorites
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href={`/account/collection/${userId}`}
        rel='nofollow'
      >
        Collections
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href='/accounts/orders'
        rel='nofollow'
      >
        Orders
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href='/accounts/offers'
        rel='nofollow'
      >
        Offers
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href='/accounts/settings'
        rel='nofollow'
      >
        Account
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href='/checkout'
        rel='nofollow'
      >
        Cart
      </SALink>
    </>
  )
}

const CollectorLinks = ({ profileUrl, userId }) => {
  return (
    <>
      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href={`${profileUrl}`}
        rel='nofollow'
      >
        View Profile
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href={`/account/favorite/${userId}`}
        rel='nofollow'
      >
        Favorites
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href={`/account/collection/${userId}`}
        rel='nofollow'
      >
        Collections
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href='/accounts/orders'
        rel='nofollow'
      >
        Orders
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href='/accounts/offers'
        rel='nofollow'
      >
        Offers
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href='/accounts/settings'
        rel='nofollow'
      >
        Account
      </SALink>

      <SALink
        data-gtm-event='nav header'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAccountLink)}
        href='/checkout'
        rel='nofollow'
      >
        Cart
      </SALink>

      <span className={styles.accountDropDownContentLine} />

      <SALink
        data-gtm-event='nav header'
        className={classNames(
          'defaultTypographyMedium',
          styles.accountDropDownContentAccountBecome,
          styles.accountDropDownContentAccountLink
        )}
        href='/whysell'
        rel='nofollow'
      >
        <StudioIcon />
        <span>Become an Artist</span>
      </SALink>
    </>
  )
}

const AccountLinksDropDown = (props: Props) => {
  const { userTypeId, dropdownIsOpen, dropdownIsHovered, authenticated } = props
  const { mobileHeaderConfig } = useHeaderV2Context()
  if (!authenticated) {
    return null
  }
  return (
    <div
      className={classNames(styles.accountDropDownContent, styles.accountDropDownContentAccount, {
        [styles.accountDropDownContentOpen]:
          dropdownIsOpen ||
          dropdownIsHovered ||
          (mobileHeaderConfig.isMobileMenuOpen && mobileHeaderConfig.mobileMenuContent === 'user'),
      })}
      data-type='account-dropdown-content'
    >
      {userTypeId === ARTIST_TYPE ? <ArtistLinks {...props} /> : <CollectorLinks {...props} />}

      <span className={styles.accountDropDownContentLine} />

      <SALink
        data-gtm-event='nav header'
        className={classNames(
          'defaultTypographyMedium',
          styles.accountDropDownContentAccountLogout,
          styles.accountDropDownContentAccountLink
        )}
        href='/easel_api/auth/logout'
        onClick={async (event: React.SyntheticEvent<HTMLElement>) => {
          event.preventDefault()
          // API will DELETE session and we refresh the page
          await getLogout()
          location.reload()
        }}
        rel='nofollow'
      >
        Log Out
      </SALink>
    </div>
  )
}

export default SessionConnector(AccountLinksDropDown)
